<template>
    <div class="factory-statistics">
        <a-form layout="inline" :labelCol="{span: 4}" :wrapperCol="{span: 20}">
        <a-row :gutter="20">
            <a-col :span="8">
                <a-form-item label="厂商名称" >
                    <a-input placeholder="输入厂商名称" v-model="params.factoryName" allowClear @pressEnter="onSearch" @change="onSearch"/>
                </a-form-item>
            </a-col>
              <a-col :span="8">
                <a-form-item label="查询时间" >
                       <a-range-picker  v-model="selectTime" @change="changeTime" @ok="changeTime" allowClear/>
                </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-table  size="small" bordered  :columns="columns" :data-source="tableData" style="margin-top: 20px;" :loading="loading" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"   :pagination="pagination" >
        
        </a-table>
    </div>
</template>
<script>
import moment from 'moment'
import { queryFactoryStatisticsApi } from '@/api/factory'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
        {
            title: '厂商名称',
            dataIndex: 'factoryName',
            key: 'factoryName',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '已有设备(台)',
            dataIndex: 'equipmentNumbers',
            key: 'equipmentNumbers',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '设备新增数量(台)',
            dataIndex: 'segmentEquipmentNumbers',
            key: 'segmentEquipmentNumbers',
            ellipsis: true,
            align: 'center'
        },
        {
            title: '活跃设备数量(台)',
            dataIndex: 'hasDataEquipment',
            key: 'hasDataEquipment',
            ellipsis: true,
            align: 'center'
        },
]
export default {
    data() {
        return{
            params: { page: 1 },
            tableData: [],
            columns,
            loading: false,
            selectedRowKeys: [],
            rowSelection,
            selectTime: [moment(), moment()],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
        }
    },
    mounted() {
        this.params.startTime = this.selectTime[0]
        this.params.endTime = this.selectTime[1]
        this.queryFactoryStatistics()
    },
    methods: {
        changePage(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.queryFactoryStatistics()
        },
        onSearch() {
            this.queryFactoryStatistics()
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        queryFactoryStatistics() {
            this.loading = true
            this.params.page = this.pagination.current
            this.params.limit = this.pagination.pageSize
            queryFactoryStatisticsApi({...this.params}).then(res => {
                this.tableData = res.result.content
                this.pagination.current = res.result.number + 1
                this.pagination.total = res.result.totalElements
                this.params.page = this.pagination.current
                this.loading = false
            })
        },
        changeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryFactoryStatistics()
        },
    }
}
</script>
<style lang="scss">
.factory-statistics{
  .ant-form-item{
      width: 100%;
    }
}
</style>